import React from "react";
import video from "../assets/ContrAst Teaser.mp4";

const Video = () => {
  return (
    <div>
      <video
        style={{
          width: "100%",
          height: "100vh",
          position: "absolute",
        }}
        src={video}
        controls
      />
    </div>
  );
};

export default Video;
