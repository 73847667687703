import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

//single pages
import MainPage from "./pages/MainPage";
import ForecastingPage from "./pages/ForecastingPage";
import GlobalSolutionPage from "./pages/GlobalSolutionPage";
import ContactUsPage from "./pages/ContactUsPage";

import Video from "./pages/Video";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    // errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        index: true,
        element: <MainPage />,
      },
      // {
      //   path: "/forecasting",
      //   element: <ForecastingPage />,
      // },
      // {
      //   path: "/solution",
      //   element: <GlobalSolutionPage />,
      // },
      // {
      //   path: "/contact-us",
      //   element: <ContactUsPage />,
      // },
    ],
  },
  {
    path: "/ContrAst-Teaser",
    element: <Video />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

if ("serviceWorker" in navigator) {
  navigator.serviceWorker.ready.then((registration) => {
    registration.unregister();

    if (caches) {
      caches.keys().then(async (names) => {
        await Promise.all(names.map((name) => caches.delete(name)));
      });
    }
  });
}
