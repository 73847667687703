import smallarrow from "../assets/images/smallarrow.png";
import bigarrow from "../assets/images/bigarrow.png";
import small from "../assets/images/small.svg";
import big from "../assets/images/big.svg";
import x from "../assets/images/x.svg";
import vector from "../assets/images/vector.svg";
import systemic from "../assets/images/systemic.svg";
import unreliable from "../assets/images/unreliable.svg";
import detrimental from "../assets/images/detrimental.svg";
import quasar from "../assets/images/quasar.svg";
import macbook from "../assets/images/macbook.svg";
import group from "../assets/images/group.svg";
import align from "../assets/images/align.svg";
import quantify from "../assets/images/quantify.svg";
import steer from "../assets/images/steer.svg";
import repeat from "../assets/images/repeat.svg";
import solutionmobile from "../assets/images/solutionmobile.svg";
import homex from "../assets/images/homex.svg";
import intro from "../assets/images/intro3.svg";
// import mobileintro from "../assets/images/newmobile.svg";
import tabletintro from "../assets/images/tabletintro.svg";
// import {ReactComponent as intro} from "../assets/images/intro3.svg";
// import {ReactComponent as tabletintro} from "../assets/images/tabletintro.svg";
import { ReactComponent as MobileIntro } from "../assets/images/artboard.svg";
import * as React from "react";

import { useEffect, useRef, useState } from "react";
import gsap from "gsap";
import HomeHeader from "../components/HomeHeader";
import ContactUsForm from "../components/ContactUsForm";

//styles and functions for tab in solution section
const tabStyle = {
  cursor: "pointer",
  color: "#B7B7B7",
  fontSize: "18px",
  lineHeight: "28px",
  fontWeight: 600,
  marginBottom: "0px",
  textAlign: "left",
};
const tabStyleRepeat = {
  color: "#B7B7B7",
  fontSize: "18px",
  lineHeight: "28px",
  fontWeight: 600,
  marginBottom: "0px",
  textAlign: "left",
};

const activeTabStyle = {
  // cursor: "pointer",
  borderBottom: "2px solid #CABD7A",
  color: "#008f90",
  fontSize: "18px",
  lineHeight: "28px",
  fontWeight: 600,
  marginBottom: "0px",
};
const activeTabStyleRepeat = {
  color: "#008f90",
  fontSize: "18px",
  lineHeight: "28px",
  fontWeight: 600,
  marginBottom: "0px",
};

function Item(props) {
  const { label, content, isActive, onClick, image } = props;

  const containerStyle = {
    width: "100%",
    textAlign: "left",
    fontWeight: "400",
    fontSize: "18px",
    lineHeight: "28px",
    fontFamily: "inter, sans-serif",
    marginLeft: "5px",
  };

  const labelContainerStyle = {
    color: label === isActive ? "#008F90" : "#B7B7B7",
    borderBottom: isActive ? "2px solid #CABD7A" : "1px solid silver",
    display: "flex",
    alignItems: "center",
    width: "100%",
    justifyContent: "space-between",
  };
  const labelStyle = {
    cursor: "pointer",
    color: isActive ? "#008F90" : "#B7B7B7",
  };

  const imageStyle = {
    marginRight: "5px",
    maxWidth: "24px",
  };
  const vectorImageStyle = {
    marginRight: "5px",
    maxWidth: "24px",
    color: isActive ? "#008F90" : "red",
    transform: isActive ? "" : "rotate(0deg) scaleY(-1)",
    transition: "transform 1s",
  };

  return (
    <div style={containerStyle}>
      <div onClick={onClick} style={labelContainerStyle}>
        <div style={labelStyle}>
          {image && <img src={image} alt="" style={imageStyle} />} {label}
        </div>

        <div style={vectorImageStyle}>
          <svg
            width="29"
            height="17"
            viewBox="0 0 29 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M28 15.75L14.5 2.25L1 15.75"
              stroke={isActive ? "#008F90" : "#B7B7B7"}
              strokeWidth="2"
            />
          </svg>
        </div>
      </div>

      <div
        style={{
          marginTop: "20px",
          marginBottom: "20px",
          animation: isActive ? "fadeIn 2s ease-in-out" : "",
        }}
      >
        {content}
      </div>
    </div>
  );
}

//lock and unlock viewport functions
function lockViewport() {
  const body = document.querySelector("body");
  if (body) body.style.overflow = "hidden";
}

function unlockViewport() {
  const body = document.querySelector("body");
  if (body) body.style.overflow = "";
}

const MainPage = () => {
  const [hasWheelEventOccurred, setHasWheelEventOccurred] = useState(false);
  const [scrollEnabled, setScrollEnabled] = useState(false);
  const [bannerIsStage, setBannerIsStage] = useState(true);
  const [activeItem, setActiveItem] = useState("Quantify");
  const animationBoxRef = useRef(null);
  const headerBoxRef = useRef(null);
  const containerRef = useRef(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 600);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const handleItemClick = (itemName) => {
    setActiveItem(itemName);
  };

  useEffect(() => {
    setActiveItem("Quantify");
  }, []);

  useEffect(() => {
    function handleResize() {
      const newScreenWidth = window.innerWidth;
      setScreenWidth(newScreenWidth);
      setIsMobile(newScreenWidth < 600); // Set isMobile to true if screen width is less than 600 pixels
    }

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    window.addEventListener(
      "resize",
      () => {
        const ismobile = window.innerWidth < 600;
        if (ismobile !== isMobile) setIsMobile(ismobile);
      },
      false
    );
  }, [isMobile]);

  let imageToShow = intro; // Default image

  if (screenWidth >= 992) {
    imageToShow = intro; // Use the default image for screens bigger than 992 pixels
  } else if (screenWidth >= 600) {
    imageToShow = tabletintro; // Use the mediumIntro image for screens between 600 and 992 pixels
  }
  // else {
  //   imageToShow = mobileintro; // Use the tabletIntro image for screens lower than 600 pixels
  // }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  // function runAnimation() {
  //   const animationBox = animationBoxRef.current;
  //   const headerBox = headerBoxRef.current;
  //   const container = containerRef.current;

  //   if (animationBox) {
  //     gsap.to(container, {
  //       duration: 3,
  //       opacity: 0,
  //       onComplete: () => {
  //         gsap.to(container, {
  //           duration: 0.5,
  //           display: "none",
  //           visibility: "hidden",
  //         });
  //       },
  //     });

  //     const screenWidth = window.innerWidth;
  //     let scaleValue = 100;
  //     let translateXValue = "-810%";
  //     let translateYValue = "400%";

  //     if (screenWidth < 600) {
  //       // Adjust properties for smaller screens
  //       scaleValue = 50;
  //       translateXValue = "-550%";
  //       translateYValue = "380%";
  //         animationDuration = 4;

  //     } else if (screenWidth >= 600 && screenWidth < 992) {
  //       // Adjust properties for screens between 600 and 992 pixels
  //       scaleValue = 100;
  //       translateXValue = "-950%";
  //       translateYValue = "520%";
  //     }

  //     gsap.to(animationBox, {
  //       opacity: 0,
  //       duration: 8,
  //       scale: scaleValue,
  //       translateX: translateXValue,
  //       translateY: translateYValue,
  //       ease: "ease.out",
  //       onComplete: () => {
  //         setBannerIsStage(false);
  //         gsap.to(animationBox, {
  //           duration: 0.5,
  //           display: "none",
  //           visibility: "hidden",
  //         });

  //         gsap.to(headerBox, {
  //           duration: 0.5,
  //           opacity: 0,
  //           onComplete: () => {
  //             gsap.to(headerBox, {
  //               duration: 0.5,
  //               display: "none",
  //               visibility: "hidden",
  //             });
  //           },
  //         });
  //       },
  //     });
  //   }
  // }

  function runAnimation() {
    const animationBox = animationBoxRef.current;
    const headerBox = headerBoxRef.current;
    const container = containerRef.current;

    if (animationBox) {
      gsap.to(container, {
        duration: 3,
        opacity: 0,
        onComplete: () => {
          gsap.to(container, {
            duration: 0.5,
            display: "none",
            visibility: "hidden",
          });
        },
      });

      const screenWidth = window.innerWidth;
      let scaleValue = 100;
      let translateXValue = "-810%";
      let translateYValue = "400%";
      let animationDuration = 8; // Default duration for scaleValue = 100

      if (screenWidth < 600) {
        // Adjust properties for smaller screens
        scaleValue = 50;
        translateXValue = "-550%";
        translateYValue = "750%";
        animationDuration = 3; // Change duration for scaleValue = 50
      } else if (screenWidth >= 600 && screenWidth < 992) {
        // Adjust properties for screens between 600 and 992 pixels
        scaleValue = 100;
        translateXValue = "-950%";
        translateYValue = "520%";
      }

      gsap.to(animationBox, {
        opacity: 0,
        duration: animationDuration, // Use the dynamically set duration
        scale: scaleValue,
        translateX: translateXValue,
        translateY: translateYValue,
        ease: "ease.out",
        onComplete: () => {
          setBannerIsStage(false);
          gsap.to(animationBox, {
            duration: 0.5,
            display: "none",
            visibility: "hidden",
          });

          gsap.to(headerBox, {
            duration: 0.5,
            opacity: 0,
            onComplete: () => {
              gsap.to(headerBox, {
                duration: 0.5,
                display: "none",
                visibility: "hidden",
              });
            },
          });
        },
      });
    }
  }

  //wheel effect
  useEffect(() => {
    lockViewport();

    function handleWheel() {
      if (!scrollEnabled && bannerIsStage) {
        runAnimation();
        setTimeout(() => {
          setScrollEnabled(true);
          unlockViewport();
        }, 3000);
        setHasWheelEventOccurred(true);
      }

      // Remove the event listener when it's not needed
      window.removeEventListener("wheel", handleWheel);
    }

    if (!isMobile) {
      window.addEventListener("wheel", handleWheel);
      window.addEventListener("click", handleWheel);
    } else {
      window.addEventListener("touchmove", handleWheel);
      window.addEventListener("click", handleWheel);
    }

    return () => {
      window.removeEventListener("wheel", handleWheel);
    };
  }, []);

  const handleContactButtonClick = () => {
    // Trigger the animation when the "Contact Us" button is clicked
    runAnimation();
  };

  return (
    <div className="App">
      {hasWheelEventOccurred && <HomeHeader />}

      {!bannerIsStage ? (
        <div className="page-top-home">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h1 style={{ marginBottom: "0px" }}>
                  REVOLUTIONIZING SALES FORECASTING FOR THE LIFE SCIENCES
                  INDUSTRY.
                </h1>

                <h4>
                  Ataraxys streamlines activities and processes across the
                  organisation to improve forecast accuracy, execute the plan
                  and deliver on target.
                </h4>
                <div className="arrow-container">
                  <div className="arrow-wrapper">
                    <img
                      src={small}
                      alt="small arrow"
                      className="small-arrow"
                    />
                  </div>
                  <div className="arrow-wrapper">
                    <img src={big} alt="big arrow" className="big-arrow" />
                  </div>
                  <h5 className="scroll">SCROLL</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      <div className="header" ref={headerBoxRef}>
        <div className="first-box">
          <div className="zooming-box">
            {isMobile ? (
              <MobileIntro
                ref={animationBoxRef}
                className="scroll-image-mobile"
              />
            ) : (
              <img
                ref={animationBoxRef}
                src={imageToShow}
                alt="ataraxys"
                className="scroll-image"
              />
            )}
          </div>
        </div>

        <div className="container " ref={containerRef}>
          <div className="row"></div>
          <div className="row">
            <div className="col-lg-12 button">
              <a href="#contact" smooth={true}>
                <button className="btn" onClick={handleContactButtonClick}>
                  contact us
                </button>
              </a>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="arrow-container">
                <div className="arrow-wrapper">
                  <img
                    src={smallarrow}
                    alt="small arrow"
                    className="small-arrow"
                  />
                </div>
                <div className="arrow-wrapper">
                  <img src={bigarrow} alt="big arrow" className="big-arrow" />
                </div>
                <h5 className="scroll">SCROLL</h5>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="cover ">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <video
                id="video"
                autoPlay
                playsInline
                loop
                muted
                style={{
                  width: "100%",
                  height: "100vh",
                  position: "absolute",
                  zIndex: 10,
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  objectFit: "cover",
                }}
              >
                <source src="https://cdn.contrastpharma.com/img/homevideo" />
              </video>
            </div>
          </div>
        </div>
      </div>

      <section
        className="section padding-bottom-80 colored"
        style={{ overflow: "hidden" }}
      >
        <div
          className="container"
          style={{ position: "relative" }}
          id="forecasting"
        >
          <div className="row">
            <div className="col-lg-8 col-md-12 col-sm-12 ">
              <div className="left-heading">
                <h2 className="section-title">FORECASTING</h2>
              </div>
              <div className="left-text">
                <p>
                  Chronic inaccuracy of sales forecasts causes substantial
                  profit loss
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <img src={x} className="x-logo" alt="" />
              <img src={homex} className="x-logo-mobile" alt="" />
            </div>
          </div>
        </div>
      </section>
      <section
        className="section padding-bottom-80 colored"
        style={{ overflow: "hidden", paddingTop: "80px" }}
      >
        <div className="container ">
          <div className="center-text">
            <div className="row">
              <div className="forecast">
                <div className="col-lg-4 ">
                  <img
                    src={systemic}
                    alt="systemic-causes"
                    style={{ marginTop: "40px" }}
                    className="mobile-image"
                  />
                </div>
                <div className="col-lg-8 ">
                  <h3 className="title">
                    Systemic causes
                    <br />
                    <img src={vector} alt="" />
                    <span className="results">
                      System, organisation and process roots. Because
                      forecasting is:
                      <br />
                      <svg
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        height="7px"
                        width="7px"
                        transform="rotate(180)"
                      >
                        <path d="M4.431 12.822l13 9A1 1 0 0019 21V3a1 1 0 00-1.569-.823l-13 9a1.003 1.003 0 000 1.645z" />
                      </svg>
                      <strong> Manual: </strong>
                      Excel, PowerPoint and vendor or homegrown non-integrated
                      solutions
                      <br />
                      <svg
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        height="7px"
                        width="7px"
                        transform="rotate(180)"
                      >
                        <path d="M4.431 12.822l13 9A1 1 0 0019 21V3a1 1 0 00-1.569-.823l-13 9a1.003 1.003 0 000 1.645z" />
                      </svg>
                      <strong> Fragmented: </strong>
                      multiple departments involved, at local, regional and
                      global levels
                      <br />
                      <svg
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        height="7px"
                        width="7px"
                        transform="rotate(180)"
                      >
                        <path d="M4.431 12.822l13 9A1 1 0 0019 21V3a1 1 0 00-1.569-.823l-13 9a1.003 1.003 0 000 1.645z" />
                      </svg>
                      <strong> Unstructured: </strong>
                      lack of ownership, e-mail borne alignment, countless
                      meetings
                    </span>
                  </h3>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="forecast">
                <div className="col-lg-4 ">
                  <img
                    src={unreliable}
                    alt="systemic-causes"
                    style={{ marginTop: "40px" }}
                    className="mobile-image"
                  />
                </div>
                <div className="col-lg-8 ">
                  <h3 className="title">
                    Unreliable results
                    <br />
                    <img src={vector} alt="" />
                    <span className="results">
                      <strong>40% of launches miss</strong> their 2-year target
                      ; limited improvement over the first 5 years. <br />
                      <strong>
                        Deviation median of 30% i.e. revenues overestimated
                      </strong>
                      <></> by nearly a third. 55% of executives have little
                      confidence in forecasts
                    </span>
                  </h3>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="forecast">
                <div className="col-lg-4 ">
                  <img
                    src={detrimental}
                    alt="systemic-causes"
                    style={{ marginTop: "40px" }}
                    className="mobile-image"
                  />
                </div>
                <div className="col-lg-8 ">
                  <h3 className="title">
                    Detrimental consequences
                    <br />
                    <img src={vector} alt="" />
                    <span className="results">
                      <strong>
                        Up to 10% of lost operating income each year
                      </strong>{" "}
                      i.e. tens to hundreds of millions of dollars. Endless
                      hours to craft the narrative to explain deviations every
                      quarter and year-end
                    </span>
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="section padding-bottom-80 colored"
        style={{ overflow: "hidden" }}
      >
        <div
          className="container"
          style={{ position: "relative" }}
          id="solution"
        >
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 ">
              <div className="left-heading-solution .section-title">
                <h2 className="section-title">SOLUTION</h2>
              </div>
              <div className="left-text">
                <p>
                  QwASR* streamlines processes across &nbsp;the organisation to
                  improve forecast accuracy, execute and deliver the plan
                </p>
                <h5>
                  * A name lent by the brightest object in the universe; an
                  acronym describing the whole forecast lifecycle
                </h5>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="section padding-bottom-80 colored"
        style={{ overflow: "hidden", paddingTop: "0px", marginTop: "0px" }}
        id="mobile"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 ">
              <img src={quasar} alt="" style={{ width: "100%" }} id="quasar" />
              <img
                src={solutionmobile}
                alt=""
                style={{ width: "100%" }}
                id="solutionmobile"
              />
            </div>
            <div className="col-lg-6 col-md-6 component-desktop">
              <div className="row">
                <div
                  className="col-lg-3 tab"
                  onClick={() => handleItemClick("Quantify")}
                >
                  <img src={quantify} alt="" />

                  <div
                    className={`tab ${
                      activeItem !== "Quantify" ? "active" : ""
                    }`}
                    style={
                      activeItem === "Quantify" ? activeTabStyle : tabStyle
                    }
                  >
                    Quantify
                  </div>
                </div>
                <div
                  className="col-lg-3 tab"
                  onClick={() => handleItemClick("Align")}
                >
                  <img src={align} alt="" />

                  <div
                    className={`tab ${activeItem !== "Align" ? "active" : ""}`}
                    style={activeItem === "Align" ? activeTabStyle : tabStyle}
                  >
                    Align
                  </div>
                </div>

                <div
                  className="col-lg-3 tab"
                  onClick={() => handleItemClick("Steer")}
                >
                  <img src={steer} alt="" />
                  <div
                    className={`tab ${activeItem !== "Steer" ? "active" : ""}`}
                    style={activeItem === "Steer" ? activeTabStyle : tabStyle}
                  >
                    Steer
                  </div>
                </div>

                <div
                  className="col-lg-3 tab"
                  // onClick={() => handleItemClick("Repeat")}
                >
                  <img src={repeat} alt="" />
                  <div
                    className={`tab ${activeItem !== "Repeat" && ""}`}
                    style={tabStyleRepeat}
                  >
                    Repeat
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="fade-animation" key={activeItem}>
                  {activeItem === "Quantify" && (
                    <>
                      Team's specific modelling modules (Forecasting:
                      patient-based model; Pricing: IRP etc.)
                      <br />
                      <br />
                      Productivity features to avoid repetitive tasks and
                      off-system activities;
                      <br /> keep focus on market dynamic understanding &
                      strategy definition
                      <br />
                      <br />
                      Integration of internal & external data sources for
                      inconsistency or unrealistic values detection
                    </>
                  )}
                  {activeItem === "Align" && (
                    <>
                      Scenario sharing; automated notifications when a team
                      refers to a obsolete or unapproved scenario
                      <br />
                      <br />
                      Consensus based alignment
                      <br />
                      <br />
                      Manual, semi-automated or fully automated
                      cross-organisation scenario updates
                    </>
                  )}
                  {activeItem === "Steer" && (
                    <>
                      Governance workflows at function level (launch price /
                      price change requests, volume revisions etc.)
                      <br />
                      <br />
                      Constant actual vs. plan deviation measurement and
                      analysis
                      <br />
                      <br />
                      Advanced and exhaustive reporting and dashboards
                    </>
                  )}
                  {activeItem === "Repeat" && ""}
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 component-mobile">
              <Item
                image={quantify}
                label="Quantify"
                content={
                  activeItem === "Quantify" && (
                    <>
                      Team's specific modelling modules (Forecasting:
                      patient-based model; Pricing: IRP etc.)
                      <br />
                      <br />
                      Productivity features to avoid repetitive tasks and
                      off-system activities; keep focus on market dynamic
                      understanding & strategy definition
                      <br />
                      <br />
                      Integration of internal & external data sources for
                      inconsistency or unrealistic values detection
                    </>
                  )
                }
                isActive={activeItem === "Quantify"}
                onClick={() => handleItemClick("Quantify")}
              />
              <Item
                image={align}
                label="Align"
                content={
                  activeItem === "Align" && (
                    <>
                      Scenario sharing; automated notifications when a team
                      refers to a obsolete or unapproved scenario
                      <br />
                      <br />
                      Consensus based alignment
                      <br />
                      <br />
                      Manual, semi-automated or fully automated
                      cross-organisation scenario updates
                    </>
                  )
                }
                isActive={activeItem === "Align"}
                onClick={() => handleItemClick("Align")}
              />
              <Item
                image={steer}
                label="Steer"
                content={
                  activeItem === "Steer" && (
                    <>
                      Governance workflows at function level (launch price /
                      price change requests, volume revisions etc.)
                      <br />
                      <br />
                      Constant actual vs. plan deviation measurement and
                      analysis
                      <br />
                      <br />
                      Advanced and exhaustive reporting and dashboards
                    </>
                  )
                }
                isActive={activeItem === "Steer"}
                onClick={() => handleItemClick("Steer")}
              />
              <div
                style={{ marginTop: "15px" }}
                className="col-lg-3 tab"
                // onClick={() => handleItemClick("Repeat")}
              >
                <img src={repeat} alt="" />
                <div
                  style={
                    activeItem === "Repeat"
                      ? activeTabStyleRepeat
                      : tabStyleRepeat
                  }
                >
                  Repeat
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className="section padding-bottom-80 colored"
        style={{ paddingTop: "80px" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-6 col-sm-12">
              <img src={macbook} alt="" style={{ width: "100%" }} />
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="row">
                <div className="solution">
                  <h2>
                    Forecasting with QwASR relies on the integration of
                    functional specific modules and across-functional alignment
                    features
                    <br />
                    <br />
                    QwASR disrupts the codes of the user's journey with IT
                    systems
                    <br />
                    <br />
                    Forecast accuracy gains are directly visible on the bottom
                    line
                  </h2>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-12 col-sm-12"></div>
            <div className="col-lg-7 col-md-12 col-sm-12 last-img">
              <img
                src={group}
                alt=""
                style={{ width: "100%" }}
                className="group"
              />
            </div>
          </div>
        </div>
      </section>
      <section
        className="section background id"
        style={{ position: "relative", height: "426px" }}
        id="contact"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="row">
                <div className="center-heading">
                  <h3>CONTACT US</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="center-content-contact-home">
        <div
          className="container"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <div
            className="col-lg-6 col-md-12 col-sm-10 px-5 mb-10"
            style={{ backgroundColor: "#fff" }}
          >
            <ContactUsForm />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainPage;
