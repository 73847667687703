import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import whitelogo from "../assets/images/whitelogo.svg";

const HomeHeader = () => {
  const [isActive, setIsActive] = useState(false);

  const handleClick = () => {
    setIsActive(!isActive);
  };

  const [isMobile, setIsMobile] = useState(window.innerWidth < 992);

  useEffect(() => {
    window.addEventListener(
      "resize",
      () => {
        const ismobile = window.innerWidth < 992;
        if (ismobile !== isMobile) setIsMobile(ismobile);
      },
      false
    );
  }, [isMobile]);

  return (
    <div>
      <header
        className="header-area-home"
        style={{
          position: "absolute",
          color: "#fff!important",
          zIndex: 2000,
        }}
      >
        <div className={`${isMobile ? "container-fluid" : "container"}`}>
          <div className="row">
            <div className="col-12">
              <nav className="main-nav">
                <Link to="/" className="logo">
                  <img
                    src={whitelogo}
                    alt="Ataraxys"
                    style={{ width: "271px" }}
                  />
                </Link>

                <ul className={`nav ${isActive ? "show-header" : ""}`}>
                  <li>
                    <Link smooth to="/">
                      HOME
                    </Link>
                  </li>
                  <li>
                    <HashLink smooth to="/#forecasting">
                      FORECASTING
                    </HashLink>
                  </li>
                  <li>
                    <HashLink smooth to="/#solution">
                      SOLUTION
                    </HashLink>
                  </li>
                  <li>
                    <HashLink smooth to="/#contact">
                      CONTACT
                    </HashLink>
                  </li>
                </ul>

                <a
                  className={`menu-trigger ${isActive ? "active" : ""}`}
                  onClick={handleClick}
                >
                  <span>Menu</span>
                </a>
              </nav>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};

export default HomeHeader;
