import React from "react";
import linkedin from "../assets/images/linkedin.svg";

const Footer = () => {
  return (
    <section
      className="section-footer "
      style={{ borderTop: "2px solid #CABD7A" }}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 align-self-center mobile-bottom-fix">
            <div className="second-left-text">
              <div className="row">
                <div
                  className="col-lg-9 col-md-6 col-sm-12"
                  style={{ marginBottom: "15px" }}
                >
                  <a href="mailto:info@ataraxys.com">info@ataraxys.com</a>
                  <br />
                  <br />
                  <span className="address">
                    Atarach Systems (Ataraxys) AG
                    <br />
                    Landis+Gyr-strasse 1
                    <br />
                    6300 Zug
                    <br />
                    Switzerland
                  </span>
                </div>

                <div className="col-lg-2 col-md-6 col-sm-12">
                  <a
                    href="https://www.linkedin.com/company/ataraxys/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={linkedin} alt="" />
                  </a>

                  <h2>Legal mentions</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Footer;
