import React, { useState } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Swal from "sweetalert2";

const schema = yup.object({
  FirstName: yup.string().required("First name is required"),
  LastName: yup.string().required("Last name is required"),
  Phone: yup
    .number()
    .transform((value, originalValue) => {
      if (originalValue === "") {
        return undefined;
      }
      return value;
    })
    .nullable()
    .typeError("Please enter a valid phone number"),

  Email: yup
    .string()
    .required("Email is required")
    .email("Please enter a valid email address"),
  Company: yup.string().required("Company is required"),
});

const ContactUsForm = () => {
  const [loading, setLoading] = useState(false);
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({
    mode: "all",
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      const response = await axios.post(
        "https://ataraxysapi.contrastpharma.com/api/v1/public/contacts",

        { ...data, Phone: data.Phone ? String(data.Phone) : "" }
      );

      if (response.data.Message === "SUCCESS") {
        Swal.fire({
          position: "top-center",
          icon: "success",
          title: "Message successfully sent!",
          showConfirmButton: false,
          timer: 3000,
        });

        reset();
      } else {
        Swal.fire({
          position: "top-center",
          icon: "error",
          title: "Something went wrong!",
          showConfirmButton: false,
          timer: 3000,
        });
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      Swal.fire({
        position: "top-center",
        icon: "error",
        title: "Something went wrong!",
        showConfirmButton: false,
        timer: 3000,
      });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="row">
        <div className="col-lg-6 col-md-6 col-sm-12">
          <div className="contact-us">
            <label>First Name*</label>
            <input
              placeholder="Your first name"
              {...register("FirstName", { required: true })}
            />
            <span>{errors.FirstName?.message}</span>
          </div>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 ">
          <div className="contact-us">
            <label>Last Name*</label>
            <input
              placeholder="Your last name"
              {...register("LastName", { required: true })}
            />
            <span>{errors.LastName?.message}</span>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6 col-md-6 col-sm-12 contact-us">
          <div className="contact-us">
            <label>Phone</label>
            <input
              placeholder="Your phone number"
              {...register("Phone", { required: false })}
            />
            <span>{errors.Phone?.message}</span>
          </div>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-12 ">
          <div className="contact-us">
            <label>Email*</label>
            <input
              placeholder="Your email address"
              {...register("Email", { required: true })}
            />
            <span>{errors.Email?.message}</span>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6 col-md-6 col-sm-12">
          <div className="contact-us">
            <label>Company*</label>
            <input
              placeholder="Your company"
              {...register("Company", { required: true })}
            />
            <span>{errors.Company?.message}</span>
          </div>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-12 ">
          <div className="contact-us">
            <label>Job</label>
            <input
              placeholder="Your job"
              {...register("Job", { required: false })}
            />
            <span>{errors.Job?.message}</span>
          </div>
        </div>
      </div>
      <div className="col-lg-12">
        <div className="contact-us">
          <label>Message</label>
          <textarea
            style={{ width: "100%", height: "150px", marginTop: "5px" }}
            type="text"
            placeholder="Type your message here"
            {...register("Message", { required: false })}
          ></textarea>
          <span>{errors.Message?.message}</span>
        </div>
      </div>
      <div className="col-lg-12">
        <button
          type="submit"
          disabled={loading}
          className={loading ? "disabled" : "btn"}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            SEND
          </div>
        </button>
      </div>
    </form>
  );
};

export default ContactUsForm;
